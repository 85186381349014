<script>
import SchemeComponent from '@/views/ArgumentedReality/Scheme/Scheme'
import JsModal from '@/components/JsModal'
import store from '@/store'
import { mapState } from 'vuex'
import CoverScheme from '@/views/ArgumentedReality/Common/CoverScheme'
import PublishDialog from '@/views/ArgumentedReality/Scheme/Dialog/Publish'
import DeleteDialog from '@/views/ArgumentedReality/Scheme/Dialog/Delete'
import { listForClasses } from '@/classes'
import ARScheme from '@/classes/modules/AR/ARScheme'
import ARScene from '@/classes/modules/AR/ARScene'

/**
 *
 https://push2.eastmoney.com/api/qt/stock/get?invt=2&fltt=1&cb=jQuery35108922368034845725_1715917213362&fields=f58%2Cf734%2Cf107%2Cf57%2Cf43%2Cf59%2Cf169%2Cf301%2Cf60%2Cf170%2Cf152%2Cf177%2Cf111%2Cf46%2Cf44%2Cf45%2Cf47%2Cf260%2Cf48%2Cf261%2Cf279%2Cf277%2Cf278%2Cf288%2Cf19%2Cf17%2Cf531%2Cf15%2Cf13%2Cf11%2Cf20%2Cf18%2Cf16%2Cf14%2Cf12%2Cf39%2Cf37%2Cf35%2Cf33%2Cf31%2Cf40%2Cf38%2Cf36%2Cf34%2Cf32%2Cf211%2Cf212%2Cf213%2Cf214%2Cf215%2Cf210%2Cf209%2Cf208%2Cf207%2Cf206%2Cf161%2Cf49%2Cf171%2Cf50%2Cf86%2Cf84%2Cf85%2Cf168%2Cf108%2Cf116%2Cf167%2Cf164%2Cf162%2Cf163%2Cf92%2Cf71%2Cf117%2Cf292%2Cf51%2Cf52%2Cf191%2Cf192%2Cf262%2Cf294%2Cf295%2Cf269%2Cf270%2Cf256%2Cf257%2Cf285%2Cf286%2Cf748%2Cf747&secid=0.002455&ut=fa5fd1943c7b386f172d6893dbfba10b&wbp2u=8556376410290276%7C0%7C1%7C0%7Cweb&_=1715917213363
 */
export default {
  components: {
    SchemeComponent,
    CoverScheme,
    JsModal,
    PublishDialog,
    DeleteDialog,
  },
  data () {
    return {
      status: 1, // 0 草稿 1 已发布
      loading: false,
      editData: undefined,
      editScene: undefined,
      publish: 1, // 0 下线 1 上线
      maxNum: 6,
      moreScene: false,
      page: undefined,
    }
  },
  computed: {
    ...mapState({
      sceneList: state => state.ar.sceneList,
      sceneData: state => state.ar.sceneData,
    }),
    showDetail () {
      return this.$route.name === 'ARSchemeList'
    },
  },
  mounted () {
    this.init()
  },
  watch: {
    '$route.name': function (o, n) {
      this.init()
    }
  },
  methods: {
    async init () {
      // console.log(this.$route.name)
      this.getMax()
      store.commit('clearArScene')
      if (this.$route.name === 'ARSchemeList') {
        // 没有白膜数据则返回全部页面
        // console.log('没有白膜数据则返回全部页面')
        if ((this.sceneData ?? '') === '') {
          this.$router.push({
            name: 'ARScheme'
          })
          return
        }
        await this.getSchemeBySceneId()
      } else {
        await this.getList()
      }

    },
    getMax () {
      // console.log(this.$refs.content_group)
      if (this.$refs.content_group) {
        const contentGroupEle = this.$refs.content_group
        const clientWidth = contentGroupEle.clientWidth
        // console.log(clientWidth)
        const maxNum = Math.floor(( clientWidth - 80 ) / 280)
        // console.log(Math.floor(maxNum))
        this.maxNum = maxNum > 6 ? 6 : maxNum
      }
    },
    async getSchemeBySceneId () {
      this.loading = true
      const res = await store.dispatch(
        'getSchemeBySceneId',
        {
          status: this.status,
          modelId: this.$route.params.id,
        }
      )
      this.loading = false
      if (res.message === 'success') {
        // store.commit('setArSceneList', res.contents.list)
        this.sceneData.planList = listForClasses({
          list: res.contents.page.result,
          classes: ARScheme
        })
        // console.log(this.sceneData)
        // console.log([
        //   ...[this.sceneData]
        // ])
        store.commit(
          'setArSceneList',
          [
            ...[this.sceneData]
          ]
        )
      }
    },
    async getList () {
      this.loading = true
      const res = await store.dispatch(
        'getArSchemeList',
        {
          status: this.status,
          pn: this.page ? this.page.nextPage : 1
        }
      )
      this.loading = false
      if (res.message === 'success') {
        this.$set(
          this,
          'page',
          res.contents.page
        )
        const list = [
          ...this.sceneList,
          ...listForClasses({
            list: res.contents.page.result,
            classes: ARScene
          })
        ]
        store.commit(
          'setArSceneList',
          list
        )
        // if (res.contents.page && res.contents.page.result.length === 20) {
        //   this.moreScene = true
        // } else {
        //   this.moreScene = false
        // }
        if (res.contents.page) {
          const { page } = res.contents
          const {
            pageNo,
            totalPages
          } = page
          this.moreScene = pageNo < totalPages
        }

      }
      // await store.dispatch('createArScheme')
    },
    async getMoreSceneList () {
      const res = await this.getList()
    },
    changeStatus (status) {
      if (this.loading) {
        return
      }
      if (this.status === status) {
        this.$set(
          this,
          'page',
          undefined
        )
        return
      }
      this.status = status
      // store.commit('clearArScene')
      this.init()
    },
    openCoverDialog (params) {
      // console.log(params)
      this.$set(
        this,
        'editData',
        params.scheme
      )
      this.$set(
        this,
        'editScene',
        params.scene
      )
      this.$modal.show('coverDialog')
    },
    closeCoverDialog () {
      // console.log('closeEditScheme')
      this.$modal.hide('coverDialog')
    },
    closePublish () {
      this.$modal.hide('publishDialog')
    },
    openPublishDialog (params) {
      this.publish = params.publish
      this.$set(
        this,
        'editData',
        params.scheme
      )
      this.$set(
        this,
        'editScene',
        params.scene
      )
      this.$modal.show('publishDialog')
    },
    closeDeleteDialog () {
      this.$modal.hide('deleteDialog')
    },
    openDeleteDialog (params) {
      this.$set(
        this,
        'editData',
        params.scheme
      )
      this.$set(
        this,
        'editScene',
        params.scene
      )
      this.$modal.show('deleteDialog')
    },
    goToMore (params) {
      store.commit('setArSceneData', params)
      this.$router.push({
        name: 'ARSchemeList',
        params: {
          id: params.id
        }
      })
    },
    goToAll () {
      this.$router.push({
        name: 'ARScheme',
      })
    }
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="v_ar_scheme d-flex flex-column bbox"
>
  <div
    class="tab_group bbox d-flex align-center"
  >
    <div
      class="tab"
      :class="{
        on: status === 1
      }"
      @click.stop="changeStatus(1)"
    >
      已发布
    </div>
    <div
      class="tab"
      :class="{
        on: status === 0
      }"
      @click.stop="changeStatus(0)"
    >
      草稿箱
    </div>
  </div>
  <div
    v-if="showDetail"
    class="navigator_group d-flex align-center bbox"
  >
    <div
      class="name"
      @click.stop="goToAll"
    >
      {{ status ? '已发布' : '草稿箱' }}
    </div>
    <div
      class="text"
    >
      &gt;
    </div>
    <div
      class="name"
    >
      {{ sceneData.name }}
    </div>
  </div>
  <div
    ref="content_group"
    class="content_group bbox"
  >
    <div
      class="scene_group"
    >
      <template
        v-for="(item, index) in sceneList"
      >
        <div
          :key="index"
          v-if="item.planList && item.planList.length"
          class=""
        >
          <div
            v-if="!showDetail"
            class="scene_name_group d-flex align-center justify-space-between"
          >
            <div
              class="scene_name"
              @click.stop="goToMore(item)"
            >
              {{ item.name }}
            </div>
            <div
              v-if="item.planList.length >= maxNum"
              class="more cp"
              @click.stop="goToMore(item)"
            >
              更多&gt;
            </div>
          </div>

          <div
            class="scheme_list d-flex flex-wrap align-center"
          >
            <template
              v-for="(scheme, schemeIndex) in item.planList"
            >
              <div
                v-if="showDetail ? 1 : schemeIndex + 1 <= maxNum"
                class="scheme flex-shrink-0"
                :key="schemeIndex"
              >
                <SchemeComponent
                  :scheme="scheme"
                  :scene="item"
                  :show-cover="Boolean(1)"
                  :show-delete="!Boolean(status)"
                  :show-offline="Boolean(status)"
                  :show-publish="!Boolean(status)"
                  :show-edit="Boolean(1)"
                  @coverHandler="openCoverDialog"
                  @publishHandler="openPublishDialog"
                  @deleteHandler="openDeleteDialog"
                ></SchemeComponent>
              </div>
            </template>
          </div>
        </div>

      </template>
      <div
        v-show="moreScene"
        class="scene_more_group"
      >
        <div
          class="more cp"
          @click.stop="getMoreSceneList"
        >
          加载更多
        </div>
      </div>
    </div>
  </div>
  <modal
    name="coverDialog"
    height="auto"
  >
    <JsModal
      :title="'编辑封面'"
      @closeHandler="closeCoverDialog"
    >
      <CoverScheme
        :scheme="editData"
        :scene="editScene"
        @closeHandler="closeCoverDialog"
      ></CoverScheme>
    </JsModal>
  </modal>
  <modal
    name="publishDialog"
    height="auto"
    width="300px"
  >
    <JsModal
      :title="'提示'"
      @closeHandler="closePublish"
    >
      <PublishDialog
        :scheme="editData"
        :scene="editScene"
        :status="publish"
        @closeHandler="closePublish"
      ></PublishDialog>
    </JsModal>
  </modal>
  <modal
    name="deleteDialog"
    height="auto"
    width="300px"
  >
    <JsModal
      :title="'提示'"
      @closeHandler="closeDeleteDialog"
    >
      <DeleteDialog
        :scheme="editData"
        :scene="editScene"
        @closeHandler="closeDeleteDialog"
      ></DeleteDialog>
    </JsModal>
  </modal>
</div>
</template>

<style scoped lang="scss">
.v_ar_scheme {
  background-color: #fff;
  height: 100%;
  overflow: auto;
  padding: 40px 0;
  color: #333;
  .tab_group {
    width: 100%;
    padding: 0 40px;
    .tab {
      @include transition;
      cursor: pointer;
      width: 96px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      color: #333;
      background-color: #F3F7FB;
      text-align: center;
      margin-right: 10px;
      &.on {
        color: #fff;
        background-color: $main_blue;
      }
      //&:hover {
      //  color: #fff;
      //  background-color: $main_blue;
      //}

    }
  }
  .navigator_group {
    width: 100%;
    padding: 30px 40px 10px;
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    .text {
      padding: 0 5px;
    }
    .name {
      @include transition;
      cursor: pointer;
      &:hover {
        color: $main_blue;
      }
    }
  }
  .content_group {
    width: 100%;
    padding: 40px;
    overflow: auto;
    .scene_group {
      width: 100%;
      height: 100%;
      overflow: auto;

      .scene_name_group {
        margin-bottom: 20px;
        .scene_name {
          font-size: 20px;
        }
        .more {
          @include transition;
          font-size: 16px;
          color: #999999;
          line-height: 21px;
          &:hover {
            color: $main_blue;
          }
        }
      }
      .scheme_list {
        .scheme {
          width: 280px;
          height: 257px;
          margin: {
            right: 16px;
            bottom: 16px;
          }
        }

      }
      .scene_more_group {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        .more {
          color: $main_blue;
          user-select: none;
          font-size: 13px;
        }
      }
    }

  }
}
</style>
